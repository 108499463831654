<template>
  <v-navigation-drawer
    :value="active"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 580 : '100%'"
    class="pl-2 pr-2 pt-2 pb-2"
    app
    @input="(val) => $emit('aside', val)"
  >
    <shipping-address-form
      :resource="resource"
      :loading="loading"
      :errors="errors"
      :cols="12"
      @submit="handleSubmit"
    />
  </v-navigation-drawer>
</template>

<script>
import { useExtractErrors } from '@/composables'
import { storeShippingAddress, updateShippingAddress } from '@api/merchant/shipping-address'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'
import ShippingAddressForm from './ShippingAddressForm.vue'

export default {
  components: { ShippingAddressForm },
  model: {
    prop: 'active',
    event: 'aside',
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    merchant: {
      type: Object,
      required: true,
    },
    resource: {
      type: [Object, Boolean],
      default: false,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const errors = ref([])

    const handleSubmit = async form => {
      loading.value = true

      try {
        const response = props.resource
          ? await updateShippingAddress(props.merchant.id, props.resource.id, form)
          : storeShippingAddress(props.merchant.id, form)

        if (response) {
          emit('updated', response)
        }
      } catch (error) {
        errors.value = useExtractErrors(error)
      } finally {
        loading.value = false
      }
    }

    return {
      handleSubmit,
      loading,
      errors,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
