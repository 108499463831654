<template>
  <v-form ref="formElem">
    <v-card elevation="0">
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="6"
            class="py-1"
          >
            <v-text-field
              v-model="form.first_name"
              label="First Name"
              required
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
            class="py-1"
          >
            <v-text-field
              v-model="form.last_name"
              label="Last Name"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-select
              v-model="form.type"
              label="Address Type"
              :items="addressTypes"
              item-text="title"
              item-value="value"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            class="py-1"
          >
            <v-text-field
              v-model="form.address_line_1"
              label="Address Line 1"
              required
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            class="py-1"
          >
            <v-text-field
              v-model="form.address_line_2"
              label="Address Line 2"
              hint="Optional"
              persistent-hint
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            class="py-1"
          >
            <v-text-field
              v-model="form.address_line_3"
              label="Address Line 3"
              hint="Optional"
              persistent-hint
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <district-selector
              v-if="cities.length"
              v-model="form.district_id"
              :cities="cities"
            ></district-selector>
          </v-col>

          <v-col cols="12">
            <VuePhoneNumberInput
              v-model="form.phone"
              default-country-code="HK"
              :only-countries="['HK', 'CN', 'MO', 'KR']"
              @update="setPhoneCountryCode"
            />
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="form.phone_extension"
              label="Phone Extension"
              hint="Optional"
              persistent-hint
            />
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="form.email"
              label="Email"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-switch
              v-model="form.active"
              label="Active"
            ></v-switch>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'

import { getCities } from '@/api/district'
import { DistrictSelector } from '@/components'
import { useNotifyErrors } from '@/composables'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

export default {
  components: { VuePhoneNumberInput, DistrictSelector },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: Number,
      default: 6,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const cities = ref([])

    const getInitialData = () => ({
      address_line_1: props.resource?.address_line_1 ?? null,
      address_line_2: props.resource?.address_line_2 ?? null,
      address_line_3: props.resource?.address_line_3 ?? null,
      type: props.resource?.type ?? 'business',
      first_name: props.resource?.first_name ?? null,
      last_name: props.resource?.last_name ?? null,
      phone: props.resource?.phone ?? null,
      phone_area_code: props.resource?.phone_area_code ?? null,
      phone_country_code: props.resource?.phone_country_code ?? null,
      phone_extension: props.resource?.phone_extension ?? null,
      email: props.resource?.email ?? null,
      district_id: props.resource?.district_id ?? null,
      active: props.resource?.active ?? false,
    })

    // const initialForm = {
    //   shipping_fee: props.resource ? props.resource.shipping_fee : null,
    //   waived_at_subtotal: props.resource ? props.resource.waived_at_subtotal : null,
    // }

    const addressTypes = [
      {
        title: 'Business',
        value: 'business',
      },
      {
        title: 'Home',
        value: 'home',
      },
    ]

    const form = ref({ ...getInitialData() })

    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    const setPhoneCountryCode = data => {
      if (data?.countryCallingCode && data?.countryCallingCode !== form.value.phone_country_code) {
        form.value.phone_country_code = data.countryCallingCode
      }
    }

    onMounted(async () => {
      try {
        cities.value = await getCities()
      } catch (error) {
        useNotifyErrors(error)
      }
    })

    return {
      cities,
      addressTypes,
      form,
      formElem,
      validate,

      setPhoneCountryCode,
    }
  },
}
</script>
