import axios from '@axios'

const getShippingAddresses = async merchantId => {
  const endPoint = `/admin/merchants/${merchantId}/shipping-addresses`

  const { status, data } = await axios.get(endPoint)

  if (status == 200) {
    return data.data?.records
  }

  return null
}

const storeShippingAddress = async (merchantId, payload) => {
  const endPoint = `/admin/merchants/${merchantId}/shipping-addresses`

  const { status, data } = await axios.post(endPoint, payload)

  if (status == 201) {
    return data.data
  }

  return null
}

const updateShippingAddress = async (merchantId, shippingAddressId, payload) => {
  const endPoint = `/admin/merchants/${merchantId}/shipping-addresses/${shippingAddressId}`

  const { status, data } = await axios.put(endPoint, payload)

  if (status == 200) {
    return data.data
  }

  return null
}

const deleteShippingAddress = async (merchantId, shippingAddressId) => {
  const endPoint = `/admin/merchants/${merchantId}/shipping-addresses/${shippingAddressId}`

  const { status, data } = await axios.delete(endPoint)

  if (status == 200) {
    return data.data
  }

  return null
}

export { getShippingAddresses, storeShippingAddress, updateShippingAddress, deleteShippingAddress }
