import axios from '@axios'

const getCities = async () => {
  const { status, data } = await axios.get(`/v1/cities`)

  if (status == 200) {
    return data.data.records
  }

  return null
}

export { getCities }
