<template>
  <div>
    <shipping-address-aside
      v-if="aside === 'shipping-address'"
      :active="true"
      :merchant="merchant"
      :resource="addressData"
      @updated="refresh"
      @aside="bol => { closeAside(bol); !bol && refresh() } "
    />
    <v-card class="mb-2">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="mb-4 me-3"
          @click="toggleAside(null, 'shipping-address')"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span>Add New Address</span>
        </v-btn>
      </v-card-title>

      <v-data-table
        :headers="tableColumnHeaders"
        :items="shippingAddresses"
        :sort-by="['id']"
        :sort-desc="[true]"
        hide-default-footer
      >
        <!-- id -->
        <template #[`item.id`]="{item}">
          <div class="d-flex align-center">
            {{ item.id }}
          </div>
        </template>

        <!-- address -->
        <template #[`item.address_line_1`]="{item}">
          <div
            class="d-flex align-center"
            v-html="getFullAddress(item)"
          ></div>
        </template>

        <!-- district -->
        <template #[`item.district`]="{item}">
          <div class="d-flex align-center">
            {{ item.district.name.en }}
          </div>
        </template>

        <!-- contact person -->
        <template #[`item.first_name`]="{item}">
          <div class="d-flex align-center">
            {{ item.first_name }} {{ item.last_name }}
          </div>
        </template>

        <!-- phone -->
        <template #[`item.phone`]="{item}">
          <div class="d-flex align-center">
            {{ getFullPhoneFormat(item) }}
          </div>
        </template>

        <!-- phone -->
        <template #[`item.email`]="{item}">
          <div class="d-flex align-center">
            {{ item.email }}
          </div>
        </template>

        <!-- Action -->
        <template #[`item.action`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                link
                @click="toggleAside(item, 'shipping-address')"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon>
                  <span>Edit</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                link
                @click="destroy(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
mdiDeleteOutline, mdiDotsVertical, mdiPencil, mdiPencilOutline, mdiPlus,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'

import { deleteShippingAddress } from '@/api/merchant/shipping-address'
import { useConfirm, useNotifyErrors } from '@/composables'
import { t } from '@/plugins/i18n'
import ShippingAddressAside from '../../shipping-address-resource/ShippingAddressAside.vue'

export default {
  name: 'MerchantShippingAddressList',

  components: { ShippingAddressAside },

  props: {
    merchant: {
      type: Object,
      required: true,
    },
    shippingAddresses: {
      type: Array,
      required: true,
    },
  },

  setup(props, { emit }) {
    const aside = ref('')
    const ready = ref(false)
    const addressData = ref(null)

    const tableColumnHeaders = computed(() => [
      { text: 'ID', value: 'id', sortable: true },
      { text: 'ADDRESS', value: 'address_line_1', sortable: false },
      { text: 'DISTRICT', value: 'district', sortable: false },
      { text: 'CONTACT PERSON', value: 'first_name', sortable: false },
      { text: 'PHONE', value: 'phone', sortable: false },
      { text: 'EMAIL', value: 'email', sortable: false },
      { text: 'ACTION', value: 'action', sortable: false },
    ])

    const destroy = async address => {
      await useConfirm({
        title: 'Delete Shipping Address',
        content: 'Are you sure?',
      })

      try {
        await deleteShippingAddress(props.merchant.id, address.id)
        refresh()
      } catch (error) {
        useNotifyErrors(error)
      }
    }

    const closeAside = bol => {
      if (!bol) {
        addressData.value = null
        aside.value = null
      }
    }

    const toggleAside = slot => {
      addressData.value = slot
      aside.value = 'shipping-address'
    }

    const refresh = async () => {
      ready.value = false

      emit('updated')
      closeAside()

      ready.value = true
    }

    const getFullAddress = item => [item.address_line_1, item.address_line_2, item.address_line_3].join('<br />')

    const getFullPhoneFormat = item => {
      let { phone } = item
      if (item.phone_extension) {
        phone = `${phone} ${item.phone_extension}`
      }

      if (item.phone_area_code) {
        phone = `${item.phone_area_code} ${phone}`
      }

      if (item.phone_country_code) {
        phone = `+${item.phone_country_code} ${phone}`
      }

      return phone
    }

    // onMounted(() => {
    //   console.log(props.shippingAddresses)
    // })

    return {
      t,
      aside,
      ready,
      addressData,
      tableColumnHeaders,

      refresh,
      closeAside,
      toggleAside,

      getFullAddress,
      getFullPhoneFormat,

      destroy,

      icons: {
        mdiPencil,
        mdiPlus,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style scoped>
.cross-out {
  text-decoration: line-through;
  opacity: 0.5;
}
</style>
