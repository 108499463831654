var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.aside === 'shipping-address')?_c('shipping-address-aside',{attrs:{"active":true,"merchant":_vm.merchant,"resource":_vm.addressData},on:{"updated":_vm.refresh,"aside":function (bol) { _vm.closeAside(bol); !bol && _vm.refresh() }}}):_vm._e(),_c('v-card',{staticClass:"mb-2"},[_c('v-card-title',[_c('v-spacer'),_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.toggleAside(null, 'shipping-address')}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Add New Address")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumnHeaders,"items":_vm.shippingAddresses,"sort-by":['id'],"sort-desc":[true],"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.address_line_1",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center",domProps:{"innerHTML":_vm._s(_vm.getFullAddress(item))}})]}},{key:"item.district",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.district.name.en)+" ")])]}},{key:"item.first_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" ")])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(_vm.getFullPhoneFormat(item))+" ")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.email)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.toggleAside(item, 'shipping-address')}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('span',[_vm._v("Edit")])],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.destroy(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v("Delete")])],1)],1)],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }